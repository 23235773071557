html {
  height: 100%
}

body {
  height: 100%;
  margin: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

#render {
  height:100%;  
}

.btn-mcliq {
  background-color: #305337;
  color: #FFF;
  border-color: #305337;
}

.btn-mcliq:hover {
  background-color: #9FCFA9;
  color: #FFF;
  border-color: #9FCFA9;
}

.no-decoration {
  color: black;
}

.login-template {
  background-color: #71B823;
  height: 100%;
}

.login-page {
  padding-top: 15%;
}

.login-page h1 {
  color: #FFF;
  font-weight: bold;
  font-size: 65px;
  letter-spacing: 5px;
}

.login-page .subheader {
  margin-top: -10px;
  color: #FFF;
  font-size: 20px;
}

.qr-list-section {
  padding: 20px 20px;
}

.qr-card {
  border: 1px solid #d8d8d8;
  background-color: #FFF;
  border-radius: 10px;
}


.add-qr-div {  
  opacity: 0;
  transition: all 0.5s linear;  
  height: 0px
}

.add-qr-div.active {
  /* -webkit-transition: all 2s li;  
  -moz-transition: all 2s ease;  
  -o-transition: all 2s ease;  
  -ms-transition: all 2s ease;   */
  transition: all 0.5s linear;
  height: 30px;
  opacity: 1;
  margin-bottom:20px;
  /* display: flex; */
}

#addQR:active{
  background-color:green;
}
